<template>
  <v-autocomplete
      label="Status de Pagamento da Inadimplencia"
      placeholder="Selecione o status"
      :items="getOptions"
      item-text="text"
      item-value="id"
      outlined
      dense
      v-model="status_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-list-status"
      v-on:change="(event) => this.$emit('statusSelecionado', status_selected)"
  />
</template>
<!--item-color-->
<script>
import mixinGeral from "@/mixin/geral";

export default {
  name: "AutocompleteStatusInadimplencia",
  mixins: [mixinGeral],
  props: {
    id: Number | String,
  },
  data: () => ({
        status_selected: null,
        options_status: [
          {id: 1, text: "Pendente"},
          {id: 2, text: "Aprovado"},
          {id: 4, text: "Negado"},
          {id: 3, text: "Repassado"},
          {id: 6, text: "Em acordo"},
          {id: 5, text: "Finalizado"},
        ],
      }
  ),
  computed: {
    getOptions() {
      if (!this.permissaoMaster) {
        this.options_status.splice(2, 1)
      }
      return this.options_status
    }
  },
  async created() {
    if (this.id) {
      this.status_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.status_selected = val;
    }
  },
}
</script>
<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Acompanhamento de Inadimplência</titulo-pagina>
        </v-col>
        <v-col cols="12">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn color="white" class="float-left" small @click="obterInadimplencias">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn color="white" class="mx-3" small @click="modalFiltro = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                  <v-chip
                      v-for="(value, key) in filtersChip" :key="key"
                      v-if="(value != null && value.length > 0)"
                      class="mx-3"
                      close
                      color="orange"
                      label
                      outlined
                      @click:close="filtros[key] = null"
                  > {{ key | primeiraLetraMaiuscula }}: {{ value | formataData }}
                  </v-chip>
                  <v-btn class="ml-3 float-end" color="orange" @click="dialogFormInadimplencia.status = true; dialogFormInadimplencia.editando = false" dark
                         small>
                    <v-icon>mdi-plus</v-icon>
                    Nova Inadimplencia
                  </v-btn>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                      :headers="headersTabela"
                      :items="dadosTabela"
                      locale="pt"
                  >
                    <template v-slot:item.cliente_status_id="{ item }">
                      <span :class="obterClasseStatus(item.cliente_status_id)">
                        {{ traduzirStatusCliente(item.cliente_status_id) }}
                      </span>
                    </template>
                    <template v-slot:item.valor="{ item }">
                      {{ item.valor | dinheiro }}
                    </template>
                    <template v-slot:item.referencia="{ item }">
                      {{ item.referencia | formataMes }}
                    </template>
                    <template v-slot:item.criado_em="{ item }">
                      {{ item.criado_em | formataData }}
                    </template>
                    <template v-slot:item.previsao_pagamento="{ item }">
                      {{ item.previsao_pagamento | formataData }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-btn v-if="permissaoMaster" text class="rounded-xl" readonly
                             :color="setColorStatus(item.status_id)">
                        {{ item.status }}
                      </v-btn>
                      <v-btn v-else text class="rounded-xl" readonly
                             :color="setColorStatus(item.status_id === 3 ? 2 : item.status_id)">
                        <span> </span>{{ item.status_id === 3 ? "Garantido" : item.status }}
                      </v-btn>
                    </template>

                    <template v-slot:item.detalhes="{ item }">
                      <v-icon color="blue"
                              @click="abrirDialogDetalhes(item)">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <template v-slot:item.garantir="{ item }">
                      <v-icon color="orange darken-1"
                              @click="abrirDialogGarantir(item)"
                              v-if="item.status_id === 1">
                        mdi-thumb-up
                      </v-icon>
                    </template>
                    <template v-slot:item.pagar="{ item }">
                      <v-icon color="green darken-1"
                              @click="dialogPagar.status = true; dialogPagar.inadimplencia_id = item.id"
                              v-if="item.status_id === 2">
                        mdi-check
                      </v-icon>
                    </template>
                    <template v-slot:item.recebido="{ item }">
                      <v-icon color="green darken-1"
                              @click="dialogAcordoFinalizar.status = true;
                                      dialogAcordoFinalizar.inadimplencia_id = item.id;
                                      dialogAcordoFinalizar.status_inadimplencia_id = item.status_id;
"
                              v-if="[3,6].includes(item.status_id)">
                        mdi-check-all
                      </v-icon>
                    </template>
                    <template v-slot:item.acoes="{ item }">
                      <v-icon
                          color="yellow darken-3"
                          @click="
                          dialogFormInadimplencia.inadimplencia_id = item.id;
                          dialogFormInadimplencia.cliente_id = item.cliente_id;
                          dialogFormInadimplencia.status = true
                          dialogFormInadimplencia.editando = true"
                      >mdi-pencil
                      </v-icon>
                      <v-icon color="red" @click="deletarInadimplencia(item.id)"> mdi-close-thick</v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <dialog-filtro
          ref="DialogFiltro"
          :aberto="modalFiltro"
          @fechar="modalFiltro = false"
          @filtrar="filtrar"
      />

      <dialog-formulario-inadimplencia
          ref="DialogFormularioInadimplencia"
          :cliente_id="dialogFormInadimplencia.cliente_id"
          :inadimplencia_id="dialogFormInadimplencia.inadimplencia_id"
          :editando="dialogFormInadimplencia.editando"
          :aberto="dialogFormInadimplencia.status"
          @fechar="dialogFormInadimplencia.cliente_id = null;
                   dialogFormInadimplencia.inadimplencia_id = null;
                   dialogFormInadimplencia.editando = false;
                   dialogFormInadimplencia.status = false;
                   obterInadimplencias()"
      />

      <dialog-detalhes
          ref="DialogDetalhes"
          :aberto="dialogDetalhes.status"
          :contrato="dialogDetalhes.contrato"
          :vistoria="dialogDetalhes.vistoria"
          :inadimplencia_id="dialogDetalhes.inadimplencia_id"
          @fechar="dialogDetalhes.status = false; dialogDetalhes.inadimplencia_id = null"
      />

      <dialogo-garantir
          ref="DialogGarantir"
          :contrato="dialogGarantir.contrato"
          :vistoria="dialogGarantir.vistoria"
          :aberto="dialogGarantir.status"
          :inadimplencia_id="dialogGarantir.inadimplencia_id"
          @fechar="dialogGarantir.status = false; obterInadimplencias()"
      />

      <dialogo-pagar
          ref="DialogoPagar"
          :aberto="dialogPagar.status"
          :inadimplencia_id="dialogPagar.inadimplencia_id"
          @fechar="dialogPagar.status = false; obterInadimplencias()"
      />

      <dialogo-acordo-finalizar
          ref="DialogAcordoFinalizar"
          :aberto="dialogAcordoFinalizar.status"
          :inadimplencia_id="dialogAcordoFinalizar.inadimplencia_id"
          :status_inadimplencia_id="dialogAcordoFinalizar.status_inadimplencia_id"
          @fechar="dialogAcordoFinalizar.status = false; obterInadimplencias()"
      />

    </v-container>
  </menu-principal>
</template>

<script>
import mixinGeral from "@/mixin/geral";
import MenuPrincipal from "@/components/MenuPrincipal";
import TituloPagina from "@/components/TituloPagina";
import DialogFiltro from "@/components/inadimplencia/DialogFiltro";
import DialogDetalhes from "@/components/inadimplencia/DialogDetalhes";
import DialogFormularioInadimplencia from "@/components/inadimplencia/DialogFormulario";
import service from "@/services/inadimplencia";
import DialogoGarantir from "@/components/inadimplencia/DialogGarantir";
import DialogoPagar from "@/components/inadimplencia/DialogPagar";
import DialogoAcordoFinalizar from "@/components/inadimplencia/DialogAcordoFinalizar";

export default {
  name: "Index",
  mixins: [mixinGeral],
  components: {
    DialogoGarantir,
    DialogoAcordoFinalizar,
    DialogFormularioInadimplencia,
    DialogDetalhes,
    MenuPrincipal,
    TituloPagina,
    DialogFiltro,
    DialogoPagar
  },
  data() {
    return {
      dialogDetalhes: {
        status: false,
        inadimplencia_id: null,
        contrato: '',
        vistoria: '',
      },
      dialogGarantir: {
        status: false,
        inadimplencia_id: null,
        contrato: '',
        vistoria: '',
      },
      dialogPagar: {
        status: false,
        inadimplencia_id: null
      },
      dialogAcordoFinalizar: {
        status: false,
        inadimplencia_id: null,
        status_inadimplencia_id: null
      },
      dialogFormInadimplencia: {
        status: false,
        cliente_id: null
      },
      comprovante: null,
      expandir: false,
      dadosExpandidos: [],
      modalFiltro: false,
      idPacoteExclusao: null,

      filtros: {
        nome: null,
        status_id: null,
      },
      headers: [],
      dados: [],
    };
  },
  async mounted() {
    this.obterInadimplencias();
    this.filtros.status_id = 1;
  },
  computed: {
    dadosTabela() {
      const dados = this.registrosFiltrados;
      if (dados.length === 0) {
        return []
      }
      return dados;
    },
    headersTabela() {
      var headers = [
        {text: "Cod.", value: "id"},
        {text: "Cliente", value: "nome"},
        {text: "Cliente Status", align: "center", value: "cliente_status_id"},
        {text: "Mes Referente", value: "referencia"},
        {text: "Valor", value: "valor", align: "right"},
        {text: "Status", value: "status", align: "center"},
        {text: "Previsao de Pagamento", value: "previsao_pagamento"},
        {text: "Detalhes", value: "detalhes", width: "0", align: "center"},
      ];
      if (this.permissaoMaster) {
        headers.push(
            {text: "Aprovar/Negar", value: "garantir", width: "0", align: "center"},
            {text: "Repassar", value: "pagar", width: "0", align: "center"},
            {text: "Finalizar", value: "recebido", width: "0", align: "center"},
            {text: "Editar/Deletar", value: "acoes", align: "center"},
        )
      }
      return headers
    },
    filtersChip() {
      const retorno = {};
      if (this.filtros.nome) retorno.nome = `${this.filtros.nome}`;
      switch (this.filtros.status_id) {
        case 1:
          retorno.status_id = `Pendente`;
          break;
        case 2:
          retorno.status_id = `Pago`;
          break;
      }
      return retorno;
    },
    registrosFiltrados() {
      if (!this.dados) return [];
      let filtrados = [...this.dados];
      const filtrandoNome = !!this.filtros.nome;
      const filtrandoSatusId = !!this.filtros.status_id;

      if (filtrandoNome) filtrados = filtrados.filter((f) => {
        return f.nome.toUpperCase().indexOf(this.filtros.nome.toUpperCase()) !== -1;
      });
      if (filtrandoSatusId) filtrados = filtrados.filter((f) => {
        return f.status_id == this.filtros.status_id
      });
      return filtrados;
    },

  },
  methods: {
    abrirDialogDetalhes(item) {
      this.dialogDetalhes.inadimplencia_id = item.id;
      this.dialogDetalhes.contrato = item.contrato_imobiliaria;
      this.dialogDetalhes.vistoria = item.vistoria_imobiliaria;
      this.dialogDetalhes.status = true
    },
    abrirDialogGarantir(item) {
      this.dialogGarantir.inadimplencia_id = item.id;
      this.dialogGarantir.contrato = item.contrato_imobiliaria;
      this.dialogGarantir.vistoria = item.vistoria_imobiliaria;
      this.dialogGarantir.status = true;
    },
    obterClasseStatus(status) {
      let retorno = "font-weight-bold";

      if (status === 1) return (retorno += " green--text");
      if (status === 2) return (retorno += " red--text");
      return (retorno += " grey--text text--darken-1");
    },
    filtrar(obj) {
      this.filtros = obj;
    },
    async obterInadimplencias() {
      await service.listar(this.form).then(resp => {
        this.dados = resp.data
      });
    },
    async deletarInadimplencia(inadimplencia_id) {
      this.$confirm(
          "Deseja realmente Deletar essa Inadimplencia?"
      ).then((res) => {
        if (res) {
          service.deletar(inadimplencia_id);
          this.obterInadimplencias();
        }
      });
    }
  }
}
</script>
<style scoped>
</style>

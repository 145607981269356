<template>
  <div>
    <v-dialog v-model="aberto" width="700" persistent :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="fechar">
      <v-card class="card-detalhes">
        <v-card-title class="titulo-card cor-principal">
          <div class="d-flex justify-space-between w-100">
            <div>Detalhes da inadimplência</div>
            <v-btn icon dark @click="fechar()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="mt-5" v-if="!!dados">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  label="Cliente"
                  :value="form.nome"
                  outlined
                  dense
                  hide-details
                  readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                  label="Valor da inadimplencia"
                  :value="form.valor | money"
                  outlined
                  dense
                  hide-details
                  readonly
                  prefix="R$"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                  label="Data de Vencimento"
                  :value="form.status"
                  outlined
                  dense
                  hide-details
                  readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                  label="Mês referente"
                  :value="form.referencia | formataMes"
                  outlined
                  dense
                  hide-details
                  readonly
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                  label="Data de Vencimento"
                  :value="form.data_vencimento | formataData"
                  outlined
                  dense
                  hide-details
                  readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                  label="Previsão de Pagamento"
                  :value="form.previsao_pagamento | formataData"
                  outlined
                  dense
                  hide-details
                  readonly
                  :disabled="form.previsao_pagamento == null"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                  label="Data da Baixa"
                  :value="form.data_pagamento | formataData"
                  outlined
                  dense
                  hide-details
                  readonly
                  :disabled="form.data_pagamento == null"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                  label="Observação"
                  :value="form.observacao"
                  outlined
                  hide-details
                  rows="2"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="font-weight-bold font-size-16">Documentos da locação</span>
            </v-col>
            <v-col cols="12" sm="6">
              <file-uploader
                  visualizar
                  :url-visualizar="contrato"
                  titulo="Contrato"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <file-uploader
                  visualizar
                  :url-visualizar="vistoria"
                  titulo="Vistoria"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                  label="Resposta Auditor"
                  :value="form.resposta_auditor"
                  outlined
                  hide-details
                  rows="2"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" align="left">
              <v-btn
                  outlined
                  dense
                  hide-details
                  readonly
                  color="orange darken-4"
                  :disabled="form.fatura == null"
                  @click="dialogShowArquivo.status = true, dialogShowArquivo.arquivo = form.fatura"
              >
                <v-icon class="mr-5">mdi-attachment mdi-rotate-45</v-icon>
                Visualizar Fatura
              </v-btn>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn
                  outlined
                  dense
                  hide-details
                  readonly
                  :disabled="form.comprovante == null"
                  @click="dialogShowArquivo.status = true, dialogShowArquivo.arquivo = form.comprovante"
              >
                <v-icon class="mr-5">mdi-attachment mdi-rotate-45</v-icon>
                Visualizar Comprovante
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="orange"
              @click="fechar">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-show-arquivo
        ref="DialogShowArquivo"
        :titulo="'Comprovante de Pagamento'"
        :aberto="dialogShowArquivo.status"
        :url_arquivo="dialogShowArquivo.arquivo"
        @fechar="dialogShowArquivo.status = false"
    >
    </dialog-show-arquivo>

  </div>
</template>

<script>

import DialogShowArquivo from "@/components/DialogShowArquivo";
import service from "@/services/inadimplencia";
import FileUploader from "@/components/FileUploader.vue";

export default {
  name: "DialogDetalhes",
  components: {
    FileUploader,
    DialogShowArquivo
  },
  props: {
    aberto: Boolean,
    inadimplencia_id: Number,
    contrato: '',
    vistoria: ''
  },
  data() {
    return {
      dialogShowArquivo: {
        status: false,
        arquivo: null,
      },
      dados: {},
      form: {
        nome: null,
        status: null,
        referencia: null,
        data_vencimento: null,
        data_pagamento: null,
        valor: null,
        criado_por_nome: null,
        comprovante: null,
        observacao: null,
        resposta_auditor: null,
        previsao_pagamento: null,
      }
    };
  },
  methods: {
    fechar() {
      this.$emit('fechar', false);
    },
    async buscarInadimplencia() {
      await service.buscar(this.inadimplencia_id).then(resp => {
        this.form = resp.data
        this.form.observacao = resp.data.observacao == "null" ? '' : resp.data.observacao
      });
    }
  },
  watch: {
    inadimplencia_id(val) {
      if (val != null) {
        this.buscarInadimplencia()
      }
    }
  },
  created() {
  },
};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>

<template>
  <v-dialog
      v-model="aberto"
      width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Atualizar Pendencia para Garantido</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="loading text-center" v-if="loading_dados">
          <v-progress-circular
              size="50"
              color="orange"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row else>
          <v-col cols="12">
            <v-text-field
                label="Previsao de Pagamento"
                v-model="form.previsao_pagamento"
                type="date"
                outlined
                dense
                hide-details
            />
          </v-col>

<!--          <v-col cols="12" sm="6">-->
<!--            <v-file-input-->
<!--                accept="image/*,.pdf"-->
<!--                label="Comprovante de Pagamento"-->
<!--                counter-->
<!--                outlined-->
<!--                dense-->
<!--                truncate-length="14"-->
<!--                append-icon="mdi-attachment mdi-rotate-45"-->
<!--                prepend-icon=""-->
<!--                v-model="form.comprovante"-->
<!--            ></v-file-input>-->
<!--          </v-col>-->

          <v-col cols="12">
            <v-textarea
                outlined
                dense
                rows="2"
                value=""
                hide-details
                label="Observação/Resposta"
                v-model="form.resposta_auditor"
                placeholder="Ex: Motivo por estar Negando ou Aprovando o pagamento dessa pendencia "
            />
          </v-col>

          <v-col cols="12">
            <span class="font-weight-bold font-size-16">Documentos da locação</span>
          </v-col>

          <v-col cols="12" md="6">
            <file-uploader
                visualizar
                :url-visualizar="contrato"
                titulo="contrato"
            />
          </v-col>

          <v-col cols="12" md="6">
            <file-uploader
                visualizar
                :url-visualizar="vistoria"
                titulo="vistoria"
            />
          </v-col>


        </v-row>
        <v-row justify="center">
          <v-col cols="7">
            <v-btn
                dark
                dense
                color="red darken-5"
                class="ma-3 float-left"
                @click="form.status_id = 4, atualizarPendencia()">Negar
            </v-btn>
            <v-btn
                dark
                dense
                color="green darken-5"
                class="ma-3 float-right"
                @click="form.status_id = 2, atualizarPendencia()">Aprovar
            </v-btn>
          </v-col>
        </v-row>
        <!--        <v-row justify="center">-->
        <!--          <v-col cols="12" align="center">-->
        <!--&lt;!&ndash;            <v-btn dark color="orange darken-3" @click="baixarPendencia">Atualizar para Garantido</v-btn>&ndash;&gt;-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :disabled="loading_dados" color="orange darken-3" text @click="fechar">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import statusInadimplencia from "@/components/autocomplete/StatusInadimplencia"
import service from "@/services/inadimplencia";
import FileUploader from "@/components/FileUploader.vue";

export default {
  name: "DialogoGarantir",
  mixins: [mixinGeral],
  components: {FileUploader, statusInadimplencia},
  props: {
    aberto: Boolean,
    inadimplencia_id: Number,
    contrato: String,
    vistoria: String
  },
  data() {
    return {
      loading_dados: false,
      form: {
        comprovante: '',
        previsao_pagamento: '',
        resposta_auditor: '',
        status_id: null,
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },

  watch: {
    aberto: function (val) {
      if (val) {
        this.loading_dados = false
      } else {
        this.loading_dados = true
      }
    }
  },
  methods: {
    fechar() {
      this.$emit('fechar', false);
    },
    async atualizarPendencia() {
      var msg = this.form.status_id == 4 ? "Negar" : "Aprovar";
      this.$confirm("Deseja relamante "+msg+" o pagamento dessa Pendencia ?").then((res) => {
        if (res) {
          service.auditarInadimplencia(this.inadimplencia_id, this.form).then(resp => {
            this.fechar()
            this.setNotificacao({
              status: true,
              mensagem: resp.data.mensagem,
              categoria: 200
            });
          }).catch(error => {
            this.setNotificacao({
              status: true,
              mensagem: error.response.data.erros,
              categoria: 400
            });
          });

        }
      })

    },
  },

};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>

<template>
  <v-dialog
      v-model="aberto"
      width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Atualizar Pendencia para Repassado</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="loading text-center" v-if="loading_dados">
          <v-progress-circular
              size="50"
              color="orange"
              indeterminate
          ></v-progress-circular>
        </div>

        <v-row else>
          <v-col cols="12">
            <v-file-input
                accept="image/*,.pdf"
                label="Comprovante de Pagamento"
                counter
                outlined
                dense
                truncate-length="14"
                append-icon="mdi-attachment mdi-rotate-45"
                prepend-icon=""
                class="mt-5"
                v-model="form.comprovante"
                :value="form.comprovante"
            ></v-file-input>
          </v-col>

          <v-col align="center">
            <v-btn
                dark
                dense
                color="green darken-5"
                @click="form.status_id = 3, atualizarPendencia()">Pagar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :disabled="loading_dados" color="orange darken-3" text @click="fechar">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import service from "@/services/inadimplencia";

export default {
  name: "DialogoPagar",
  mixins: [mixinGeral],
  props: {
    aberto: Boolean,
    inadimplencia_id: Number,
  },
  data() {
    return {
      loading_dados: false,
      form: {
        comprovante: '',
        status_id: null,
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },

  methods: {
    fechar() {
      this.$emit('fechar', false);
    },
    async atualizarPendencia() {
      this.$confirm("Deseja atualizar o status da Pendencia para Repassado?").then((res) => {
        if (res) {
          service.auditarInadimplencia(this.inadimplencia_id, this.form).then(resp => {
            this.fechar()
            this.setNotificacao({
              status: true,
              mensagem: resp.data.mensagem,
              categoria: 200
            });
          }).catch(error => {
            this.setNotificacao({
              status: true,
              mensagem: error.response.data.erros,
              categoria: 400
            });
          });

        }
      })

    },
  },

};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>
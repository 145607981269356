<template>
  <v-dialog
      v-model="aberto"
      width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Filtrar Empresas</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="loading text-center" v-if="loading_dados">
          <v-progress-circular
              size="50"
              color="orange"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row else>
          <v-col cols="12">
            <v-text-field
                label="Nome do Cliente"
                placeholder="Nome do Cliente"
                outlined
                dense
                v-model.trim="form.nome"
                hide-details
                clearable
                prepend-inner-icon="mdi-domain"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
          <v-text-field
                label="Mês referente"
                outlined
                dense
                v-model.trim="form.referencia"
                hide-details
                clearable
                type="month"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <statusInadimplencia :id="form.status_id" v-on:statusSelecionado="statusRetornado"/>
          </v-col>


        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
            :disabled="loading_dados"
            color="orange darken-3"
            text
            @click="fechar">Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import statusInadimplencia from "@/components/autocomplete/StatusInadimplencia"
export default {
  name: "DialogoFiltro",
  mixins: [mixinGeral],
  components: {statusInadimplencia},
  props: {
    aberto: Boolean
  },
  data() {
    return {
      options_empresas: [],
      loading_dados: false,
      form: {
        nome: null,
        status_id: null
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },

  watch: {
    aberto: function (val) {
      if (val) {
        this.loading_dados = false
      } else {
        this.loading_dados = true
      }
    }
  },
  methods: {
    statusRetornado(value) {
      this.form.status_id = value
    },
    fechar() {
      this.$emit('fechar', false);
    }
  },

};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>
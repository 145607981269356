<template>
  <v-dialog
      v-model="aberto"
      width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Atualizar Pendencia para: Em acordo ou Finalizado</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="loading text-center" v-if="loading_dados">
          <v-progress-circular
              size="50"
              color="orange"
              indeterminate
          ></v-progress-circular>
        </div>

        <v-row else>
          <v-col cols="12" align="center">
            <p class="font-size-16">Deseja alterar o Status da Inadimplencia para <strong>Em acordo</strong> ou <strong>Finalizado</strong>?</p>
          </v-col>

          <v-col align="center">
            <v-btn
                v-if="status_inadimplencia_id !=6"
                dark
                dense
                class="ma-3"
                color="black darken-5"
                @click="form.status_id = 3, atualizarPendencia(6)">Em acordo
            </v-btn>

            <v-btn
                dark
                dense
                class="ma-3"
                color="green darken-5"
                @click="form.status_id = 3, atualizarPendencia(5)">Finalizar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :disabled="loading_dados" color="orange darken-3" text @click="fechar">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import service from "@/services/inadimplencia";

export default {
  name: "DialogoAcordoFinalizar",
  mixins: [mixinGeral],
  props: {
    aberto: Boolean,
    inadimplencia_id: Number,
    status_inadimplencia_id: Number,
  },
  data() {
    return {
      loading_dados: false,
      form: {
        comprovante: '',
        status_id: null,
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },
  methods: {
    fechar() {
      this.$emit('fechar', false);
    },
    async atualizarPendencia(status_id) {
      var descricao = status_id == 5 ? 'Finalizado' : 'Em Acordo';
      this.$confirm("Deseja atualizar o status da Pendencia para "+descricao+"?").then((res) => {
        if (res) {
          service.atualizar(this.inadimplencia_id, status_id).then(resp => {
            this.fechar()
            this.setNotificacao({
              status: true,
              mensagem: resp.data.mensagem,
              categoria: 200
            });
          }).catch(error => {
            this.setNotificacao({
              status: true,
              mensagem: error.response.data.erros,
              categoria: 400
            });
          });

        }
      })

    },
  },

};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>